<template>
  <section class="partner-section">
    <v-container>
      <div class="mb-20">
        <p class="section-title section-title__why white--text">Why dgFactor</p>
      </div>
    </v-container>
    <v-container>
      <v-row class="mb-20">
        <v-col cols="12" md="3" class="d-md-block d-none"> </v-col>
        <v-col cols="12" md="4">
          <v-img src="@/assets/img/us/g2.png" width="438" class="img-border-radius"></v-img>
        </v-col>
        <v-col cols="12" md="5">
          <div class="pa-3 mt-20">
            <h1 class="white--text why-title">為什麼新創公司都選擇我們</h1>
            <p class="why-content">
              我們是新創好夥伴，新創的步伐與眼界必定超前，數位因子長期協助眾多新創事業，所以我們理解您，也能為您精心規劃出，符合你們發展階段的開發需求，無論您是要建立一個MVP雛型，或是滾動式開發，或是您需要一個穩定且高效的平台，每個階段我們都能給您最佳建議，我們不只幫您開發系統，更是您的新創顧問與最佳策略夥伴。
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-20 flex-md-row flex-column-reverse">
        <v-col cols="12" md="5">
          <div class="pa-3 mt-15 mr-md-10">
            <h1 class="white--text why-title">我們考慮到您未來的發展</h1>
            <p class="why-content">
              數位因子為您設計的系統，不只會滿足您當下的需求，做出讓您滿意的成品，我們更會考慮到您未來系統功能的延展性與擴展性，讓您的平台可以永續發展，並能擴大規模。
              當您發展到一定規模，想要自建團隊維護，數位因子有完整的開發文件，並有良好的教育訓練系統，可以協助技術轉移與交接。
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-img src="@/assets/img/us/g1.png" width="642" height="417" class="img-border-radius"></v-img>
        </v-col>
        <v-col cols="12" md="1" class="d-md-block d-none"> </v-col>
      </v-row>
    </v-container>

    <!-- <v-container>
      <div class="mt-20">
        <p class="section-title section-title__partners white--text">&nbsp;</p>
        <div style="height: 160px">
          <DynamicMarquee direction="row" :speed="{ type: 'pps', number: 50 }">
            <div class="d-flex">
              <v-img
                class="mr-2"
                src="@/assets/img/partner/C1.png"
                width="260"
                height="160"
              ></v-img>
              <v-img
                class="mr-2"
                src="@/assets/img/partner/C2.png"
                width="260"
                height="160"
              ></v-img>
              <v-img
                class="mr-2"
                src="@/assets/img/partner/C3.png"
                width="260"
                height="160"
              ></v-img>
              <v-img
                class="mr-2"
                src="@/assets/img/partner/C4.png"
                width="260"
                height="160"
              ></v-img>
              <v-img
                class="mr-2"
                src="@/assets/img/partner/ecv.png"
                width="260"
                height="160"
              ></v-img>
              <v-img
                class="mr-2"
                src="@/assets/img/partner/hakka.png"
                width="260"
                height="160"
              ></v-img>
              <v-img
                class="mr-2"
                src="@/assets/img/partner/lego.png"
                width="260"
                height="160"
              ></v-img>
              <v-img
                class="mr-2"
                src="@/assets/img/partner/ogilvy.png"
                width="260"
                height="160"
              ></v-img>
              <v-img
                class="mr-2"
                src="@/assets/img/partner/seiko.png"
                width="260"
                height="160"
              ></v-img>
              <v-img
                class="mr-2"
                src="@/assets/img/partner/spalding.png"
                width="260"
                height="160"
              ></v-img>
              <v-img
                class="mr-2"
                src="@/assets/img/partner/tnf.png"
                width="260"
                height="160"
              ></v-img>
              <v-img
                class="mr-2"
                src="@/assets/img/partner/volvo.png"
                width="260"
                height="160"
              ></v-img>
            </div>
          </DynamicMarquee>
        </div>
      </div>
    </v-container> -->
    <!-- <div>
      <v-container>
        <div class="section-content-wrapper">
          <v-container>
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="5" class="mt-md-15">
                <div class="partner-card mt-md-10">
                  <p class="partner-card__text">
                    本公司於2001年成立，早期主要提供網站建置、網站代管、AWS/GCP雲端運算規劃建置與維護等服務，至今已服務超過500多家客戶，服務的客戶包括上市上櫃公司、政府機關與知名企業，也遍及美澳及中國
                  </p>
                  <div class="d-flex">
                    <p class="partner-card__name">
                      安媒 總經理 <span>高醫師</span>
                    </p>
                    <img src="" alt="" />
                  </div>
                </div>
                <div class="partner-card">
                  <p class="partner-card__text">
                    本公司於2001年成立，早期主要提供網站建置、網站代管、AWS/GCP雲端運算規劃建置與維護等服務，至今已服務超過500多家客戶，服務的客戶包括上市上櫃公司、政府機關與知名企業，也遍及美澳及中國
                  </p>
                  <div class="d-flex">
                    <p class="partner-card__name">
                      安媒 總經理 <span>高醫師</span>
                    </p>
                    <img src="" alt="" />
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="5" class="mt-md-15">
                <div class="partner-card">
                  <p class="partner-card__text">
                    本公司於2001年成立，早期主要提供網站建置、網站代管、AWS/GCP雲端運算規劃建置與維護等服務，至今已服務超過500多家客戶，服務的客戶包括上市上櫃公司、政府機關與知名企業，也遍及美澳及中國
                  </p>
                  <div class="d-flex">
                    <p class="partner-card__name">
                      安媒 總經理 <span>高醫師</span>
                    </p>
                    <img src="" alt="" />
                  </div>
                </div>
                <div class="partner-card mb-10">
                  <p class="partner-card__text">
                    本公司於2001年成立，早期主要提供網站建置、網站代管、AWS/GCP雲端運算規劃建置與維護等服務，至今已服務超過500多家客戶，服務的客戶包括上市上櫃公司、政府機關與知名企業，也遍及美澳及中國
                  </p>
                  <div class="d-flex">
                    <p class="partner-card__name">
                      安媒 總經理 <span>高醫師</span>
                    </p>
                    <img src="" alt="" />
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-container>
    </div> -->
  </section>
</template>

<script>
import DynamicMarquee from "vue-dynamic-marquee";
export default {
  components: {
    DynamicMarquee,
  },
};
</script>