<template>
  <section class="service-section">
    <v-container>
      <p class="section-title section-title__service white--text text-center">
        你自由奔放的想像，我們為您實現
      </p>
    </v-container>
    <v-container>
      <div class="service-section__content section-content-wrapper my-15">
        <v-row class="align-center">
          <v-col
            cols="12" md="4"
            class="d-flex flex-row justify-center align-center"
          >
            <div class="service-card d-flex align-center">
              <div>
                <v-img src="~/assets/img/001.png" width="80"></v-img>
              </div>
              <div class="mx-5">
                <p class="text-5 mb-2 service-card__title">官方網站</p>
                <span class="service-card__content"
                  >形象官網、活動型網站、特殊需求官網(審核上架機制)</span
                >
              </div>
            </div>
          </v-col>
          <v-col
            cols="12" md="4"
            class="d-flex flex-row justify-center align-center"
          >
            <div class="service-card d-flex align-center">
              <div>
                <v-img src="~/assets/img/002.png" width="80"></v-img>
              </div>
              <div class="mx-5">
                <p class="text-5 mb-2 service-card__title">功能性網站</p>
                <span class="service-card__content"
                  >電商平台、直播/串流/影音平台、比價網、公文系統</span
                >
              </div>
            </div>
          </v-col>
          <v-col
            cols="12" md="4"
            class="d-flex flex-row justify-center align-center"
          >
            <div class="service-card d-flex align-center">
              <div>
                <v-img src="~/assets/img/003.png" width="80"></v-img>
              </div>
              <div class="mx-5">
                <p class="text-5 mb-2 service-card__title">APP 製作</p>
                <span class="service-card__content">直播APP、IOT、APP</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </section>
</template>

<script lang="babel" type="text/babel">

export default {
  data: () => ({
    canvas: null,
    ctx: null,
    canvasWrap: null,
    curIndex: -1,
    firstCircleR: 95,
    circleMargin: 35,
  }),
  computed: {
    windowWidth() {
      return this.$vuetify.breakpoint.width;
    },
  },
  watch: {

  },
}
</script>

<style lang="sass" type="text/sass">
</style>