<template>
  <section class="tool-section">
    <v-container>
      <div >
        <p class="section-title white--text">Development Tools</p>
      </div>
    </v-container>
    <div>
      <v-container class="mt-5 mb-10">
        <div class="section-content-wrapper">
          <Slider :options="sliderOptions">
            <template v-slot:items>
              <div class="tool-list" v-for="i in photos" :key="i">
                <div v-for="j in i" :key="j" class="tool-item">
                  <!-- <v-btn class="tool-item__info-btn" color="green" small fab>
                    <v-icon class="white--text">$add</v-icon>
                  </v-btn> -->
                  <v-img width="95" height="95" :src="j"></v-img>
                </div>
              </div>
            </template>
          </Slider>
        </div>
      </v-container>
    </div>
  </section>
</template>

<script>
import Slider from "@/components/slider/slider.vue";
export default {
  components: {
    Slider,
  },
  data: () => ({
    photos: [
      [require("@/assets/img/icon/1.png"), require("@/assets/img/icon/2.png")],
      [require("@/assets/img/icon/3.png"), require("@/assets/img/icon/4.png")],
      [require("@/assets/img/icon/5.png"), require("@/assets/img/icon/6.png")],
      [require("@/assets/img/icon/7.png"), require("@/assets/img/icon/8.png")],
      [require("@/assets/img/icon/9.png"), require("@/assets/img/icon/10.png")],
      [
        require("@/assets/img/icon/11.png"),
        require("@/assets/img/icon/12.png"),
      ],
      [
        require("@/assets/img/icon/13.png"),
        require("@/assets/img/icon/14.png"),
      ],
      [
        require("@/assets/img/icon/15.png"),
        require("@/assets/img/icon/16.png"),
      ],
      [
        require("@/assets/img/icon/17.png"),
        require("@/assets/img/icon/18.png"),
      ],
      [
        require("@/assets/img/icon/19.png"),
        require("@/assets/img/icon/20.png"),
      ],
    ],
  }),
  computed: {
    sliderOptions() {
      return {
        autoWidth: true,
        nav: false,
        dots: false,
        autoplay: true,
      };
    },
  },
};
</script>