<template>
  <Observer @on-change="onChange" :threshold="0.1">
    <div class="puzzle-block">
      <div class="animate-box" :class="boxClass">
        <div id="sprite" ref="sprite" class="img-box test-img-1">
          <div class="gradient-top">
            <!-- <h1 class="title">Your Ideas, Our Criation</h1> -->
          </div>
          <div class="gradient-down"></div>
        </div>
      </div>
    </div>
    <div class="mb-20 pa-md-10 pa-6 center-content">
      <h1 class="section-title text-center mb-9">缺角拼圖我們為您補齊</h1>
      <p class="puzzle-content">
        不管您需要哪一項技術與服務，我們都能提供；如果您是同業，或是您組了一個開發團隊，但缺少哪一個開發角色，都歡迎與我們聯繫，我們很樂意為您補齊開發拼圖。
      </p>
    </div>
  </Observer>
</template>

<script>
import Observer from "vue-intersection-observer";
export default {
  components: {
    Observer,
  },
  data: () => ({
    total: 108,
    totalDeltaY: 0,
    per: 8, // 每多少px移動一偵
    index: 1,
    sprite: null,
    isIn: false,
    blockOffsetTop: 0,
    height: 900,
    scrollTop: 0,
  }),
  computed: {
    boxClass() {
      if (this.isIn) return "active";
      return null;
    },
  },
  mounted() {
    this.sprite = this.$refs.sprite;
  },
  methods: {
    loop() {
      // if (this.index >= this.total - 1) return;
      const x = -512 * ((this.index - 1) % 6);
      const y = -512 * Math.floor((this.index - 1) / 6);
      this.sprite.style.backgroundPosition = `${x}px  ${y}px`;
      this.index++;
      if (this.index == this.total) {
        this.index = 1;
      }
    },
    onChange(entry, unobserve) {
      if (entry.isIntersecting) {
        this.isIn = true;
        unobserve();
        setInterval(() => {
          this.loop();
        }, 32);
        return;
      }
      this.isIn = false;
    },
  },
};
</script>

<style lang="sass">
</style>